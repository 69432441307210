.Dashboard {
  padding-top: 3rem;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .ingredient-detail-image {
    border-radius: 50%; }
  .dashboard-button {
    color: #3D3D3D;
    width: 300px; }
  .dashboard-button:hover {
    color: white; } }


