.timeslot {
  &__dishes {
    background-color: $light;
    border-radius: $border-radius;
    position: relative;
    padding: 0.5rem;
    margin-bottom: 0.5em; }
  &__heading {
    background-color: #F2F2F2;
    padding: 0.25rem 2rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem; } }
