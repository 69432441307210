.menu_planner {
  &__week {
    margin-left: -0.5rem;
    margin-right: -0.5rem; }
  &__day {
    width: 20%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

    @media screen and ( max-width: 1200px ) {
      width: 100%;
      font-size: 0.9rem; }

    @media screen and ( max-width: 767px ) {
      min-width: 200px; } } }
